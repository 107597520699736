<template>
  <div class="mainw">
    <!-- <div 
      style="opacity: .6;position:fixed;bottom: 120px;right: 20px;border-radius: 30px;padding: 20px 12px;background-color: #1989fa;color: #fff;z-index: 999;"
      @click="()=>{this.$router.push('/info/wx996229f81e6a88f8')}"
    >
      back
    </div> -->
    <van-image src="/images/katong.jpg" height="200px" width="100%" />
    <van-cell style="background-color: #e4e4e4"
      >本机号码：<span class="msisdn-css">{{ msisdn }}</span></van-cell
    >
    <div class="number" v-for="(item, key) in wList" :key="key">
      <van-contact-card
        style="border-radius: 5px"
        type="edit"
        :name="item.remark?item.remark:''"
        :tel="item.whiteNum"
        :editable="false"
      />
      <div class="numberEdit">
        <van-button style="height:30px !important;" plain hairline type="primary" @click="showPopupUpdate(item)">修改</van-button>
      </div>
    </div>
    <div class="btn-show">
      <van-button
        id="hide"
        style="margin-bottom: 50px"
        size="large"
        color="#2860b3"
        @click="showPopup"
        >新增白名单</van-button
      >
    </div>
    <div style="font-size: 12px;padding: 0 5px;">
      <span style="color: #f00;">*</span>
      温馨提醒:
      <br/>
      1.白名单最多可添加5个11位数字的手机号码，每月最多可删除1次，每次最多删除2个，如需刑除请联系客服处理
      <br/>
      2.白名单号码提交完成后，后台会进行审核，请等待5-15分钟左右重新进入页面查询
      <br/>
    </div>
  </div>
  <common-footer />
  <!-- 新增白名单白名单 -->
  <van-popup
    v-model:show="show"
    position="bottom"
    :style="{ paddingTop: '10px', width: '100%' }"
  >
    <div
      v-for="(item, key) in whiteNumList"
      :key="key"
      style="padding-top: 5px"
    >
      <span class="remark-lable">名单{{ key + 1 }}</span>
      <van-cell-group>
        <van-field
          v-model="item.remark"
          label="姓名"
          placeholder="请填写姓名"
        />
        <van-field
          type="number"
          v-model="item.whiteNum"
          label="电话"
          placeholder="请填写电话"
        />
      </van-cell-group>
    </div>
    <div style="margin-top: 10px; padding: 10px">
      <van-button size="large" color="#ee0a24" round @click="submitForm"
        >提交号码</van-button
      >
    </div>
  </van-popup>
  <!-- 修改白名单白名单 -->
  <van-popup
    v-model:show="showUpdate"
    position="bottom"
    :style="{ paddingTop: '10px', width: '100%' }"
  >
    <div style="padding-top: 5px">
      <span class="remark-lable">修改白名单</span>
      <van-cell-group>
        <van-field
          v-model="updateFrom.remark"
          label="姓名"
          placeholder="请填写姓名"
        />
        <van-field
          disabled
          v-model="updateFrom.whiteNum"
          label="电话"
          placeholder="请填写电话"
        />
      </van-cell-group>
    </div>
    <div style="margin-top: 10px; padding: 10px">
      <van-button size="large" color="#ee0a24" round @click="submitUpdateForm"
        >确认修改</van-button
      >
    </div>
  </van-popup>
</template>

<script>
import { insertWhite, selectList, updateCardWhitenum } from "@/api/card";
import { Dialog,Notify} from "vant";
import commonFooter from "@/views/common/footer.vue";
export default {
  name: "White",
  components: {
    commonFooter,
  },
  data() {
    return {
      iccid: this.$store.getters.iccId,
      msisdn: this.$store.getters.msisdn,
      wList: [],
      whiteNumList: [],
      show: false,
      showUpdate: false,
      updateFrom:{
        id:undefined,
        iccid:undefined,
        remark:undefined,
        whiteNum:undefined,
      },
      keey:localStorage.getItem('keey')||0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      selectList(this.iccid).then((res) => {
        var wlength = 0;
        if (res != null) {
          wlength = 5 - res.length;
        } else {
          wlength = 5;
        }
        if (this.keey==1) {
          wlength = 2;
        }
        this.wList = res;
        this.whiteNumList = [];
        for (let i = 0; i < wlength; i++) {
          this.whiteNumList.push({
            remark: "",
            whiteNum: "",
          });
        }
        if (this.whiteNumList.length == 0) {
          document.getElementById("hide").style.display = "none";
        } else {
          document.getElementById("hide").style.display = "block";
        }
        this.$forceUpdate()
      });
    },
    testt(iccid,msisdn,whiteNumList) {
      for(let i = 0;i<whiteNumList.length;i++){
        whiteNumList[i].iccid=iccid;
        whiteNumList[i].msisdn=msisdn;
      }
      console.log(iccid,msisdn,whiteNumList)
    },
    submitForm() {
      let ar = []
      this.whiteNumList.forEach((n,i)=>{
        console.log(n,i)
        if(n.whiteNum){
          ar.push(n)
        }
      })
      if (this.keey==1) {
        this.testt(this.iccid, this.msisdn, ar)
      }else{

        insertWhite(this.iccid, this.msisdn, ar)
          .then((res) => {
            Notify({ type: 'success', message: '添加成功' });
            console.log(res);
            this.getList();
            this.show = false;
          })
          .catch((error) => {
            console.info(error);
          });
      }
    },
    showPopup() {
      this.show = true;
    },
    submitUpdateForm() {
      delete this.updateFrom.whiteNum
      updateCardWhitenum(this.updateFrom)
        .then((res) => {
          Notify({ type: 'success', message: '修改成功' });
          console.log(res);
          this.getList();
          this.showUpdate = false;
        })
        .catch((error) => {
          console.info(error);
        });
    },
    showPopupUpdate(item) {
      this.updateFrom.id = item.id
      this.updateFrom.iccid = item.iccid
      this.updateFrom.remark = item.remark
      this.updateFrom.whiteNum = item.whiteNum
      this.showUpdate = true;
    },
  },
};
</script>

<style>
.mainw {
  font-family: pingfang;
  background-color: #e4e4e4;
  width: 100%;
  height: 120%;
}
.header {
  background-color: #3185ff;
  padding: 10px 20px;
  color: white;
}
.header div:last-child {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  line-height: 1rem;
}
.number {
  display: flex;
  padding: 5px 20px;
  color: black;
}
.numberEdit {
  position: absolute;
  right: 10vw;
  margin-top: 19px;
}
.van-contact-card::before {
  background: repeating-linear-gradient(
    -45deg,
    #e1e1e1 0,
    #e1e1e1 0%,
    transparent 0,
    transparent 25%,
    #e1e1e1 0,
    #e1e1e1 45%,
    transparent 0,
    transparent 0%
  );
  background-size: 1px;
}
.msisdn-css {
  color: #fe6146;
  font-weight: bold;
}
.btn-show {
  margin-top: 10px;
  padding: 10px;
  background-color: #e4e4e4;
}
.remark-lable {
  padding-left: 10px;
  padding-bottom: 10px;
}
</style>
